export const ReadingIcon = ({
  color = "#593F4C",
  width = 36,
  height = 36,
}: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
    >
      <path
        d="M5.5069 4.24805C5.50593 4.24805 5.50484 4.24805 5.50387 4.24805C5.16338 4.24805 4.84314 4.38077 4.60132 4.62199C4.35683 4.86587 4.22217 5.1906 4.22217 5.53629V24.8533C4.22217 25.5617 4.80072 26.1394 5.51199 26.1412C8.51055 26.1484 13.5343 26.7733 17 30.4001V10.1843C17 9.94419 16.9387 9.71861 16.8229 9.53194C13.9784 4.95108 8.51224 4.25508 5.5069 4.24805Z"
        fill={color}
      />
      <path
        d="M31.778 24.8535V5.53629C31.778 5.1906 31.6433 4.86587 31.3988 4.62199C31.157 4.38077 30.8365 4.24805 30.4964 4.24805C30.4953 4.24805 30.4942 4.24805 30.4933 4.24805C27.488 4.2552 22.0218 4.9512 19.1772 9.53206C19.0615 9.71873 19.0002 9.94431 19.0002 10.1844V30.4001C22.466 26.7733 27.4897 26.1484 30.4883 26.1412C31.1994 26.1394 31.778 25.5617 31.778 24.8535Z"
        fill={color}
      />
      <path
        d="M34.7119 8.70312H33.7778V24.8536C33.7778 26.6622 32.3042 28.137 30.4928 28.1415C27.9494 28.1476 23.7557 28.6449 20.7856 31.4559C25.9224 30.1982 31.3374 31.0158 34.4235 31.7191C34.8088 31.8068 35.207 31.7162 35.5158 31.4701C35.8234 31.2247 35.9999 30.8577 35.9999 30.4639V9.99113C36 9.28095 35.4221 8.70312 34.7119 8.70312Z"
        fill={color}
      />
      <path
        d="M2.22207 24.8536V8.70312H1.288C0.577941 8.70312 0 9.28095 0 9.99113V30.4636C0 30.8575 0.176485 31.2243 0.484123 31.4697C0.792609 31.7157 1.19043 31.8067 1.57637 31.7187C4.66244 31.0153 10.0776 30.1979 15.2141 31.4556C12.2442 28.6446 8.05045 28.1474 5.50705 28.1414C3.69577 28.137 2.22207 26.6622 2.22207 24.8536Z"
        fill={color}
      />
    </svg>
  );
};
