import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../button";
import ExpceptionIcon from "src/icons/exception";

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

const GoBackBtn = () => {
  const navigate = useNavigate();
  return <Button onClick={() => navigate(-1)}>Go back</Button>;
};

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  render(): React.ReactNode {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <div className="p-10 flex h-screen bg-secondary-500 items-center">
          <div className="flex justify-evenly flex-1 border-b-2 items-center">
            <div className="flex flex-col gap-9 w-1/3">
              <div className="text-8xl font-bold">Oops!</div>
              <div className="text-lg">
                Apologies for the glitch! Please report with screenshots using
                the Report Issue link. We'll fix it ASAP. Thanks!
              </div>
              <div>
                <GoBackBtn />
              </div>
            </div>
            <div className="self-baseline">
              <ExpceptionIcon />
            </div>
          </div>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
