import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NavItem } from "src/constants/types";
import style from "./sidemenu.module.scss";
import { UpArrow } from "src/icons/upArrow";
import { DownArrow } from "src/icons/downArrow";

interface Props {
  expanded: boolean;
  data: NavItem[];
}

interface MenuProps {
  onClick: (path: string | undefined, i: number) => any;
  item: NavItem;
  index: number;
  isChild?: boolean;
  expanded: boolean;
  activeIndex?: number;
}

const MenuItem = ({
  onClick,
  item,
  index,
  activeIndex,
  isChild = false,
  expanded = false,
}: MenuProps) => {
  const location = useLocation();
  const hasChild = item?.children?.length;
  const isActivePath = (path: string): boolean =>
    location.pathname.includes(path);
  const isActive = isActivePath((hasChild ? item?.root : item?.path) as any);
  const [isExpanded, setExpanded] = useState(true);
  const isPractice = item.featureID === 1;

  // const isExpanded = hasChild && activeIndex === index;

  const stylesToApply = [
    "items-center flex w-full p-4 cursor-pointer relative",
  ];

  const subChildStyle = [
    "rounded-lg mx-2 child-menu relative py-2",
    isPractice ? "bg-primary-50 practice" : "bg-secondary-75 mock",
    expanded ? "px-2" : "",
  ];

  if (isActive) {
    stylesToApply.push(
      isChild
        ? isPractice
          ? "bg-primary-200 rounded-lg justify-center"
          : "bg-secondary-85 rounded-lg justify-center"
        : "bg-secondary-200 text-secondary justify-center",
    );
    stylesToApply.push(
      isChild
        ? isPractice
          ? style.childActive
          : style.childMockActive
        : style.active,
    );
  } else {
    stylesToApply.push("justify-center");
  }

  const handleClick = ({ path, index }: any) => {
    onClick(item?.path, index);
    setExpanded((prev) => !prev);
  };

  const getIcon = useCallback(
    (a: any) => {
      const Icon = a.icon;
      let color = hasChild ? "#593F4C" : undefined;

      if (isActive)
        color = a.featureID ? (isPractice ? "#E26AA8" : "#5858C6") : "#5858C6";

      return <Icon width={18} height={18} color={color} />;
    },
    [hasChild, isActive, isPractice],
  );

  return (
    <>
      <div
        className={stylesToApply.join(" ")}
        onClick={() => handleClick({ path: item?.path, index })}
      >
        <span>{getIcon(item)}</span>
        <span className={`px-3 flex-1 ${expanded ? "block" : "hidden"}`}>
          {item?.label}
        </span>
        {hasChild && expanded ? (
          <span className={"ml-auto"}>
            {isExpanded ? <UpArrow /> : <DownArrow />}
          </span>
        ) : null}
      </div>
      {isExpanded
        ? item?.children && (
            <div className={subChildStyle.join(" ")}>
              {item?.children?.map((child: any, j: any) => (
                <MenuItem
                  item={child}
                  index={j}
                  onClick={onClick}
                  key={child.path}
                  isChild={true}
                  expanded={expanded}
                />
              ))}
            </div>
          )
        : null}
    </>
  );
};

const SideBarMenu = ({ data, expanded }: Props) => {
  const navigate = useNavigate();
  // const [activeIndex, setActiveIndex] = useState(0);

  const onClick = useCallback(
    (path: string | undefined, i: number) => {
      if (path) {
        navigate(path);
      } else {
        // setActiveIndex((prev) => (prev === i ? 99 : i));
      }
    },
    [navigate],
  );

  return (
    <>
      {data?.map((item: any, i: number) => (
        <MenuItem
          item={item}
          index={i}
          onClick={onClick}
          // activeIndex={activeIndex}
          key={i}
          expanded={expanded}
        />
      ))}
    </>
  );
};

export default SideBarMenu;
