import { ButtonProps } from "./types";

const Button = ({
  children = "",
  size = "large",
  outlined = false,
  disabled = false,
  className = "",
  primary = true,
  ...rest
}: ButtonProps) => {
  const stylesApplied = [
    "text-base font-medium rounded-lg",
    size === "small" ? "py-2 px-4 text-sm" : "px-14 py-3",
    className,
  ];

  stylesApplied.push(
    outlined
      ? `border ${
          primary ? "border-primary" : "border-secondary"
        } bg-transparent ${primary ? "text-primary" : "text-secondary"}`
      : `border-0 ${primary ? "bg-primary" : "bg-secondary"} text-white`,
  );

  if (disabled) stylesApplied.push("opacity-50 cursor-not-allowed");

  stylesApplied.push(className);

  return (
    <button className={stylesApplied.join(" ")} {...rest} disabled={disabled}>
      {children}
    </button>
  );
};

export default Button;
