import {
  AvatarEight,
  AvatarFive,
  AvatarFour,
  AvatarOne,
  AvatarPlaceholder,
  AvatarSeven,
  AvatarSix,
  AvatarThree,
  AvatarTwo,
} from "src/assets/images";

export enum QuestionTypes {
  MCQ_RADIO = "RadioButton",
  PARA_MCQ_RADIO = "para-mcq-radio",
}

export enum CourseType {
  THINKING = "thinking",
  MATHEMATICAL = "mathematical",
  READING = "reading",
  WRITTING = "writting",
}

export const Feature = {
  PRACTICE: 1,
  MOCK: 2,
};

export const QuestionComplexityType = {
  VERY_EASY: 1,
  EASY: 2,
  MEDIUM: 3,
  HARD: 4,
  VERY_HARD: 5,
  MIXED: 6,
};

export const ProgressStatus = {
  NOT_STARTED: 1,
  IN_PROGRESS: 2,
  PAUSED: 3,
  COMPLETED: 4,
};

export const AvatarImgMap: any = {
  Avatar: AvatarPlaceholder,
  AvatarOne: AvatarOne,
  AvatarTwo: AvatarTwo,
  AvatarThree: AvatarThree,
  AvatarFour: AvatarFour,
  AvatarFive: AvatarFive,
  AvatarSix: AvatarSix,
  AvatarSeven: AvatarSeven,
  AvatarEight: AvatarEight,
};
