import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { User } from "src/utils/common";

export const API_BASE = process.env.REACT_APP_API_BASE;

const getHeader = () => {
  const token = User.get()?.token ? `Bearer ${User.get()?.token}` : "";
  return {
    Authorization: token,
    "Content-Type": "application/json",
  };
};

const handleResponse = async (res: any) => {
  if (res.ok) {
    try {
      const result = await res.json();
      return result;
    } catch (e) {
      return res;
    }
  } else {
    let result = {} as any;
    try {
      result = await res.json();
    } catch (e) {
      console.log("Error:", e);
    }
    switch (res.status) {
      case 401:
        sessionStorage.clear();
        localStorage.clear();
        window.location.href =
          window.location.origin +
          `/signin?sessionExpired=${
            result?.errorDetails?.errorMessage || result?.message
          }`;
        return res;
      default:
        if (res?.status >= 400) {
          toast.error(result?.errorDetails?.errorMessage || result?.message);
        }
        return result;
    }
  }
};

export const apiGet: any = async (url: string) => {
  try {
    const response = await fetch(API_BASE + url, {
      headers: getHeader(),
    });
    return handleResponse(response);
  } catch (error) {
    return error;
  }
};

export const apiPost = async (url: string, body: any, config: any = {}) => {
  try {
    const response = await fetch(API_BASE + url, {
      method: "POST",
      headers: getHeader(),
      body: JSON.stringify(body),
      ...config,
    });
    return handleResponse(response);
  } catch (error) {
    return error;
  }
};

export const apiUpload = async (url: string, body: any, config: any = {}) => {
  try {
    const token = User.get()?.token ? `Bearer ${User.get()?.token}` : "";
    const response = await fetch(API_BASE + url, {
      method: "POST",
      headers: { Authorization: token },
      body: body,
      ...config,
    });
    return handleResponse(response);
  } catch (error) {
    return error;
  }
};
