import { useCallback, useContext, useEffect, useState } from "react";
import { GlobalContext } from "src/context/global";

export const useBreadCrumbs = (value: any) => {
  const { dispatch } = useContext(GlobalContext);

  const udpateCrumb = useCallback((val: any = []) => {
    dispatch({
      type: "UPDATE_KEY",
      key: "breadCrumb",
      value: val,
    });
  }, []);

  useEffect(() => {
    udpateCrumb(value);
  }, []);

  return { udpateCrumb };
};
