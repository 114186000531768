import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Dropdown from "src/components/dropdown";
import { AvatarImgMap } from "src/constants/common";
import { apiPost } from "src/hooks/useFetch";
import { DownArrow } from "src/icons/downArrow";
import { UpArrow } from "src/icons/upArrow";
import { Exam, Profile, User } from "src/utils/common";

const options = [
  {
    id: 1,
    name: "Switch Profile",
    path: "/profile",
  },
  {
    id: 2,
    name: "Switch Exam",
    path: "/profile/exams",
  },
  {
    id: 3,
    name: "Logout",
    path: "/signin",
  },
];

const UserProfile = () => {
  const profile = Profile.get();
  const exam = Exam.get();
  const navigate = useNavigate();

  const handleSelect = async (item: any) => {
    if (item.id === 3) {
      const logout = await apiPost("Account/Logout", {});
      localStorage.clear();
      sessionStorage.clear();
    }
    navigate(item.path || "/");
  };

  return (
    <Dropdown
      options={options}
      onSelect={handleSelect}
      trigger={
        <div className="flex items-center cursor-pointer relative">
          <img
            src={AvatarImgMap[profile?.avatarName] || "Avatar"}
            alt="Avatar"
            width="40"
            height="40"
            className="shadow rounded-full max-w-full h-auto align-middle border-none"
            loading="lazy"
          />
          <span className="pl-4 max-w-[200px]">
            <div className="truncate">{profile?.profileName}</div>
            <div className="truncate text-sm text-gray-400">
              {exam?.examName}
            </div>
          </span>
        </div>
      }
    ></Dropdown>
  );
};

export default UserProfile;
