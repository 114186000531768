export const MockIcon = ({ color }: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5005 4.2V3.75C16.5005 2.50736 15.4931 1.5 14.2505 1.5H3.75049C2.52763 1.5 1.5055 2.528 1.5005 3.74055L1.50049 3.75V4.2H16.5005ZM12.9005 3.6C13.2319 3.6 13.5005 3.33137 13.5005 3C13.5005 2.66864 13.2319 2.4 12.9005 2.4C12.5691 2.4 12.3005 2.66864 12.3005 3C12.3005 3.33137 12.5691 3.6 12.9005 3.6ZM14.5505 3.6C14.8819 3.6 15.1505 3.33137 15.1505 3C15.1505 2.66864 14.8819 2.4 14.5505 2.4C14.2191 2.4 13.9505 2.66864 13.9505 3C13.9505 3.33137 14.2191 3.6 14.5505 3.6Z"
        fill="#898989"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.50049 4.7998H16.5005V12.1498C16.5005 13.3925 15.4931 14.3998 14.2505 14.3998H13.0267C13.2354 13.891 13.3505 13.3338 13.3505 12.7498C13.3505 12.5462 13.3365 12.3459 13.3094 12.1498C13.4862 12.1498 13.6505 12.0446 13.6505 11.8498C13.6505 11.6841 13.5162 11.5498 13.3505 11.5498H13.1829C12.987 10.8659 12.6279 10.251 12.1505 9.7498H13.3505C13.5162 9.7498 13.6505 9.61549 13.6505 9.4498C13.6505 9.28411 13.5162 9.14981 13.3505 9.14981H11.4431C10.7468 8.67643 9.90593 8.3998 9.00049 8.3998C7.01429 8.3998 5.339 9.73097 4.81811 11.5498H4.20049C4.0348 11.5498 3.90049 11.6841 3.90049 11.8498C3.90049 12.0155 4.0348 12.1498 4.20049 12.1498H4.69153C4.66447 12.3459 4.65049 12.5462 4.65049 12.7498C4.65049 13.3338 4.76558 13.891 4.97432 14.3998H3.75049C2.50784 14.3998 1.50049 13.3925 1.50049 12.1498V4.7998ZM7.50049 6.7498C7.3348 6.7498 7.20049 6.88411 7.20049 7.0498C7.20049 7.21549 7.3348 7.3498 7.50049 7.3498H13.3505C13.5162 7.3498 13.6505 7.21549 13.6505 7.0498C13.6505 6.88411 13.5162 6.7498 13.3505 6.7498H7.50049ZM3.90049 7.0498C3.90049 6.88411 4.0348 6.7498 4.20049 6.7498H5.55049C5.71618 6.7498 5.85049 6.88411 5.85049 7.0498C5.85049 7.21549 5.71618 7.3498 5.55049 7.3498H4.20049C4.0348 7.3498 3.90049 7.21549 3.90049 7.0498ZM5.76262 9.21194L4.89368 10.0809C4.71794 10.2566 4.43303 10.2566 4.25729 10.0809L3.98836 9.81193C3.87119 9.69478 3.87119 9.50483 3.98836 9.38767C4.10551 9.27051 4.29547 9.27051 4.41262 9.38767L4.57549 9.55054L5.33836 8.78767C5.45551 8.67051 5.64547 8.67051 5.76262 8.78767C5.87978 8.90482 5.87978 9.09478 5.76262 9.21194Z"
        fill="#898989"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.751 12.75C12.751 14.8211 11.072 16.5 9.00098 16.5C6.92991 16.5 5.25098 14.8211 5.25098 12.75C5.25098 10.6789 6.92991 9 9.00098 9C11.072 9 12.751 10.6789 12.751 12.75ZM9.37598 10.65C9.37598 10.4843 9.24167 10.35 9.07598 10.35C8.91029 10.35 8.77598 10.4843 8.77598 10.65V12.375H7.20098C7.03529 12.375 6.90098 12.5093 6.90098 12.675C6.90098 12.8407 7.03529 12.975 7.20098 12.975H8.92598C9.17451 12.975 9.37598 12.7735 9.37598 12.525V10.65Z"
        fill="#898989"
      />
    </svg>
  );
};
