import React, { Suspense } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes";
import Loader from "./components/loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App: React.FC = () => {
  return (
    <Suspense fallback={<Loader />}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        closeOnClick
        theme="light"
      />
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default App;
