import { generatePath } from "react-router-dom";
import { NavItem } from "src/constants/types";
// import TripleDES from "crypto-js/tripledes";
// import CryptoJS from "crypto-js";

export function findMenuItem(menuItems: NavItem[] | any, match: any): any {
  for (var i = 0; i < menuItems.length; i++) {
    if (
      menuItems[i]?.path === match.pathname ||
      menuItems[i]?.root === match.pathname
    ) {
      return {
        path: menuItems[i]?.path || menuItems[i]?.root,
        key: menuItems[i]?.path,
        label: menuItems[i]?.label,
      };
    } else if (
      menuItems[i]?.children?.length &&
      typeof menuItems[i].children === "object" &&
      match?.pathname?.includes(menuItems[i].root)
    ) {
      return findMenuItem(menuItems[i].children, match);
    }
  }
}

let localUser = null as any;
let localProfile = null as any;
let localExam = null as any;
let localTopic = null as any;
let localTopicStatus = null as any;
const setStorageData = (key: string, data: any, storage: Storage) => {
  storage.setItem(key, JSON.stringify(data));
};

export const getStorageData = (
  key: string,
  storage: Storage,
  localRef: any,
) => {
  if (!localRef) {
    localRef = JSON.parse(storage.getItem(key) as any);
  }
  return localRef;
};

const clearStorageData = (key: string, storage: Storage) => {
  storage.removeItem(key);
};

function encrypt(value: any, key: any) {
  const enctArray = CryptoJS.enc.Utf8.parse(value);
  const srctArray = CryptoJS.MD5(CryptoJS.enc.Utf8.parse(key));
  const encrypted = CryptoJS.TripleDES.encrypt(enctArray, srctArray, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
}

function decrypt(value: any, key: any) {
  const drctArray = CryptoJS.enc.Base64.parse(value);
  const srctArray = CryptoJS.MD5(CryptoJS.enc.Utf8.parse(key));
  const decrypted = CryptoJS.TripleDES.decrypt(
    {
      ciphertext: drctArray,
    } as any,
    srctArray,
    {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    },
  );
  return decrypted.toString(CryptoJS.enc.Utf8);
}

export const User = {
  set: (data: any = {}) => setStorageData("user", data, localStorage),
  get: () => {
    const data = getStorageData("user", localStorage, localUser);
    return data;
  },
  clear: () => clearStorageData("user", localStorage),
};

export const Profile = {
  set: (data: any = {}) => setStorageData("profile", data, sessionStorage),
  get: () => getStorageData("profile", sessionStorage, localProfile),
  clear: () => clearStorageData("profile", sessionStorage),
};

export const Exam = {
  set: (data: any = {}) => setStorageData("exam", data, sessionStorage),
  get: () => getStorageData("exam", sessionStorage, localExam),
  clear: () => clearStorageData("exam", sessionStorage),
};

export const Topic = {
  set: (data: any = {}) => setStorageData("topic", data, sessionStorage),
  get: () => getStorageData("topic", sessionStorage, localTopic),
  clear: () => clearStorageData("topic", sessionStorage),
};

export const TopicStatus = {
  set: (data: any = {}) => setStorageData("topicStatus", data, sessionStorage),
  get: () => getStorageData("topicStatus", sessionStorage, localTopicStatus),
  clear: () => clearStorageData("topicStatus", sessionStorage),
};

export const validateForm = (config: any[], values: any) => {
  const error: any = {};
  let isValid = true;

  config?.forEach((item) => {
    const name = item?.name;
    if (!values[name]) {
      if (isValid) isValid = false;
      error[name] = item.requiredMessage || `${name} is required`;
    } else if (item?.pattern) {
      error[name] =
        typeof item?.pattern === "function"
          ? item?.pattern(values)
          : item?.pattern?.test(values[name])
            ? null
            : item?.patternMessage || `${name} is invalid`;
      if (isValid) isValid = !Boolean(error[name]);
    }
  });

  return { error, isValid };
};

export const getFormattedDate = (date: any) => {
  if (!date || date == "0001-01-01T00:00:00") return null;

  const today = new Date(date);
  return `${today.getDate()?.toString().padStart(2, "0")}/${(
    today.getMonth() + 1
  )
    ?.toString()
    .padStart(2, "0")}/${today.getFullYear()}`;
};

export const getSecToMin = (seconds: any = 0) => {
  const minutes = seconds / 60;
  return Math.round(minutes);
};

export const getFormattedTime = (seconds: any = 0) => {
  var hours = Math.floor(seconds / 3600);
  var minutes = Math.floor((seconds % 3600) / 60);

  return {
    hours: hours,
    minutes: minutes,
  };
};

export const getCrumbsForTestRoom = (params: any, location: any) => {
  const path = generatePath(
    `${
      params?.featureId === "1" ? "practice" : "mock"
    }/:featureId/:subjectId/:subjectName`,
    params,
  );
  return [
    {
      label: params?.featureId === "1" ? "Practice Course" : "Mock Test",
      path,
    },
    {
      label: decodeURIComponent(params?.subjectName || ""),
      path,
    },
  ];
};

export const updateOrderNumbers = (data: any, startNumber: any) => {
  data?.forEach((item: any) => {
    if (item?.node === "order-number") {
      const number = item?.content?.match(/\d+/g) || [];
      const newNumber = number?.map(
        (b: any) => Number(b) + Number(startNumber),
      );
      number.forEach((a: any, i: any) => {
        item.content = item.content.replace("{{ " + a + " }}", newNumber[i]);
      });
    } else if (item?.node === "multi-text" && Array.isArray(item?.content)) {
      updateOrderNumbers(item.content, startNumber);
    }
  });

  return data;
};

export const logoutApp = () => {
  localStorage.clear();
  sessionStorage.clear();
  window.location.pathname = "/signin";
};

export function addLeadingZero(number: any) {
  // Use Math.abs to handle negative numbers
  const absoluteNumber = Math.abs(number);

  // Convert to string and add leading zero if necessary
  const formattedNumber =
    absoluteNumber < 10 ? `0${absoluteNumber}` : `${absoluteNumber}`;

  // Add a negative sign if the original number was negative
  return number < 0 ? `-${formattedNumber}` : formattedNumber;
}

export function hasExceededDuration(fromDate: any, durationInMinutes: any) {
  const currentDate: any = new Date();
  const startDate: any = new Date(fromDate + "Z");

  const timeDifferenceMillis: any = currentDate - startDate;

  const differenceInMinutes = Math.floor(timeDifferenceMillis / (1000 * 60));

  return differenceInMinutes > durationInMinutes;
}

const errors = {
  lowercase: "one lowercase letter, ",
  uppercase: "one uppercase letter, ",
  numeric: "one numeric digit, ",
  specialChar: "one special character (~!@#$%^&*_-+=`|(){}[]:;\"'<>,.?/), ",
  minLength: "8 characters long.",
};

export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*_\-+=`|(){}[\]:;"'<>,.?/])[A-Za-z\d~!@#$%^&*_\-+=`|(){}[\]:;"'<>,.?/]{8,}$/;

export function validatePassword(password: any) {
  const errorsFound = [];
  if (!password.match(passwordRegex)) {
    if (!/(?=.*[a-z])/.test(password)) errorsFound.push(errors.lowercase);
    if (!/(?=.*[A-Z])/.test(password)) errorsFound.push(errors.uppercase);
    if (!/(?=.*\d)/.test(password)) errorsFound.push(errors.numeric);
    if (!/(?=.*[~!@#$%^&*_\-+=`|(){}[\]:;"'<>,.?/])/.test(password))
      errorsFound.push(errors.specialChar);
    if (password.length < 8) errorsFound.push(errors.minLength);
    if (errorsFound.length)
      errorsFound.unshift("Password must contain at least ");
  }
  return errorsFound;
}

export function openFullscreen() {
  try {
    if (
      document.fullscreenElement ||
      (window as any).fullScreen ||
      (window.innerWidth == window.screen.width &&
        window.innerHeight == window.screen.height)
    ) {
    } else {
      var elem: any = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    }
  } catch (e) {}
}

export const getHMTime = (sec: any) => {
  const { hours, minutes } = getFormattedTime(sec);
  return `${hours.toString().padStart(2, "0")}h ${minutes
    .toString()
    .padStart(2, "0")}m`;
};
