export const HomeIcon = ({ color = "#898989" }: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <g clipPath="url(#clip0_642_5210)">
        <path
          d="M17.5147 7.82913C17.5143 7.82872 17.5139 7.82831 17.5135 7.8279L10.1709 0.485596C9.85791 0.172485 9.4418 0 8.99919 0C8.55658 0 8.14047 0.172348 7.82736 0.485458L0.488635 7.82405C0.486163 7.82652 0.483692 7.82913 0.48122 7.8316C-0.161481 8.47801 -0.160382 9.52679 0.484378 10.1716C0.778949 10.4663 1.168 10.637 1.58397 10.6548C1.60086 10.6565 1.61789 10.6573 1.63506 10.6573H1.92771V16.0608C1.92771 17.13 2.79769 18 3.86721 18H6.73986C7.03099 18 7.2672 17.7639 7.2672 17.4727V13.2363C7.2672 12.7484 7.66408 12.3515 8.15201 12.3515H9.84638C10.3343 12.3515 10.7312 12.7484 10.7312 13.2363V17.4727C10.7312 17.7639 10.9673 18 11.2585 18H14.1312C15.2007 18 16.0707 17.13 16.0707 16.0608V10.6573H16.342C16.7845 10.6573 17.2006 10.4849 17.5139 10.1718C18.1593 9.52597 18.1596 8.4754 17.5147 7.82913Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_642_5210">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
