import React, { useEffect, useReducer, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "./components/sidebar";
import Toolbar from "./components/toolbar/toolbar";
import { Exam, Profile, User } from "src/utils/common";
import { GlobalContext, GlobalReducer } from "./../../context/global";
import { apiGet, apiPost } from "src/hooks/useFetch";

function transformInput(input: any[]) {
  const output: any[] = [];

  input.forEach((item) => {
    const existingFeature = output.find((f) => f.featureID === item.featureID);

    if (existingFeature) {
      existingFeature.children.push(item);
    } else {
      const newFeature = {
        featureID: item.featureID,
        featureName: item.featureName,
        featureIcon: item.featureIcon,
        children: [item],
      };

      output.push(newFeature);
    }
  });

  return output?.sort((a: any, b: any) => a?.featureID - b?.featureID);
}

const MainLayout: React.FC = () => {
  const navigate = useNavigate();
  const [menu, toggleMenu] = useState(true);

  const [state, dispatch] = useReducer(GlobalReducer, {
    subject: [],
    breadCrumb: [],
  });

  const styleToApply = [
    "flex flex-col flex-1 max-h-fullscreen max-w-full overflow-y-auto bg-secondary-500",
  ];

  const getSubjects = async () => {
    const data = await apiPost(`Exam/GetSubject`, {});
    const result = data?.result || [];
    const subjects = transformInput(result);

    dispatch({ type: "UPDATE_KEY", key: "subject", value: subjects });
  };

  useEffect(() => {
    let profile = Profile.get();
    let exam = Exam.get();
    if (!profile?.studentID || !exam?.examID) {
      navigate("/profile");
    }

    if (!User.get()?.token) {
      navigate("/signin");
    }

    getSubjects();
  }, [navigate]);

  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      <div className={"flex h-screen"}>
        <Sidebar expanded={menu} />
        <div className={styleToApply.join(" ")}>
          <Toolbar onClickExpand={() => toggleMenu((prev) => !prev)} />
          <div className="flex-1 p-5">
            <Outlet />
          </div>
        </div>
      </div>
    </GlobalContext.Provider>
  );
};

export default MainLayout;
