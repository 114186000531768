import { createContext } from "react";

export const GlobalContext = createContext({} as any);

export const GlobalReducer = (state: any, action: any) => {
  switch (action.type) {
    case "UPDATE_KEY":
      const { key, value } = action;
      return {
        ...state,
        [key]: value,
      };
    default:
      return state;
  }
};
