import { Link } from "react-router-dom";

const Breadcrumbs = ({ crumbs = [] }: any) => {
  if (crumbs.length <= 0) {
    return null;
  }
  return (
    <div>
      {crumbs?.map(({ label, path }: any = {}, key: any) =>
        key + 1 === crumbs.length ? (
          <strong key={key}>{label}</strong>
        ) : (
          <span key={key}>
            <Link to={path} className="underline">
              {label}
            </Link>
            {" > "}
          </span>
        ),
      )}
    </div>
  );
};
export default Breadcrumbs;
