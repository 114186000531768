import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AvatarOne, ParentProfile } from "src/assets/images";
import { useBreadCrumbs } from "src/hooks/useBreadCrumbs";
import { DoubleArrow } from "src/icons/doubleArrow";
import { SettingsIcon } from "src/icons/settingsIcon";
import { User } from "src/utils/common";

const ParentUser = ({ expanded }: any) => {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const [user, setUser] = useState({} as any);
  const { udpateCrumb } = useBreadCrumbs([{ label: "My Profie", path: "" }]);

  const toggleMenu = () => setShowMenu((prev: boolean) => !prev);

  const handleClick = () => {
    navigate("/profile/user");
  };

  useEffect(() => {
    setUser(User.get());
  }, []);

  return (
    <div className="sticky bottom-0 w-full border-t border-gray-200">
      <div
        className="flex justify-center items-center gap-4 cursor-pointer bg-gray-50 p-4 relative"
        onClick={handleClick}
        // onClick={toggleMenu}
      >
        <img src={ParentProfile} alt="Profile Pic" className="w-8" />
        {expanded ? (
          <>
            <div className="w-32">
              <div className="truncate font-bold">
                {user?.firstName} {user?.middleName} {user?.lastName}
              </div>
              <div className="truncate text-xs">{user?.email}</div>
            </div>
            <div className="w-5 h-5 flex justify-center items-center rounded-md">
              <SettingsIcon />
            </div>{" "}
          </>
        ) : null}
      </div>
      {/* {showMenu ? (
        <div
          className="absolute bg-white p-2 bottom-1 left-full ml-1 w-full border cursor-pointer rounded-md shadow-lg"
          onClick={toggleMenu}
        >
          <Link className="px-4 py-2 block" to="/profile/user">
            My Profile
          </Link>
        </div>
      ) : null} */}
    </div>
  );
};

export default ParentUser;
