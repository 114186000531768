import { useContext, useEffect, useState } from "react";
import { useLocation, useMatches } from "react-router-dom";
import { SidebarNav } from "src/constants/navigation";
import { findMenuItem } from "src/utils/common";
import Breadcrumbs from "src/components/breadcrums";
import User from "../user";
import { HamburgerIcon } from "src/icons/hamburger";
import { GlobalContext } from "src/context/global";

const Toolbar = ({ onClickExpand }: any) => {
  const location = useLocation();
  const { state } = useContext(GlobalContext);
  const [crumbs, setCrumbs] = useState([]);
  const matches = useMatches();

  useEffect(() => {
    const updated = [...matches];
    if (updated.length > 1) {
      updated.shift();
    }
    if (updated.length > 2 && !Object.keys(matches[0].params).length) {
      updated.pop();
    }

    const route: any = updated.map(
      (match) =>
        findMenuItem(SidebarNav, match) || {
          label: match?.params[Object.keys(match.params)[0]],
          key: match?.id,
        },
    );

    setCrumbs(route);
  }, [location, matches]);

  return (
    <div className="p-3 px-5 text-grey-100 flex flex-row justify-between items-center bg-white border-b sticky top-0 z-20">
      <div className="flex gap-6">
        <span className="cursor-pointer" onClick={onClickExpand}>
          <HamburgerIcon />
        </span>
        <Breadcrumbs crumbs={state.breadCrumb} />
      </div>
      <User />
    </div>
  );
};

export default Toolbar;
