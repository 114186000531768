export const SettingsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <mask
        id="mask0_112_6697"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="25"
      >
        <rect x="0.503906" y="0.53418" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_112_6697)">
        <path
          d="M9.75313 22.5342L9.35313 19.3342C9.13646 19.2508 8.93229 19.1508 8.74062 19.0342C8.54896 18.9175 8.36146 18.7925 8.17812 18.6592L5.20312 19.9092L2.45312 15.1592L5.02813 13.2092C5.01146 13.0925 5.00312 12.98 5.00312 12.8717V12.1967C5.00312 12.0883 5.01146 11.9758 5.02813 11.8592L2.45312 9.90918L5.20312 5.15918L8.17812 6.40918C8.36146 6.27585 8.55313 6.15085 8.75313 6.03418C8.95313 5.91751 9.15313 5.81751 9.35313 5.73418L9.75313 2.53418H15.2531L15.6531 5.73418C15.8698 5.81751 16.074 5.91751 16.2656 6.03418C16.4573 6.15085 16.6448 6.27585 16.8281 6.40918L19.8031 5.15918L22.5531 9.90918L19.9781 11.8592C19.9948 11.9758 20.0031 12.0883 20.0031 12.1967V12.8717C20.0031 12.98 19.9865 13.0925 19.9531 13.2092L22.5281 15.1592L19.7781 19.9092L16.8281 18.6592C16.6448 18.7925 16.4531 18.9175 16.2531 19.0342C16.0531 19.1508 15.8531 19.2508 15.6531 19.3342L15.2531 22.5342H9.75313ZM12.5531 16.0342C13.5198 16.0342 14.3448 15.6925 15.0281 15.0092C15.7115 14.3258 16.0531 13.5008 16.0531 12.5342C16.0531 11.5675 15.7115 10.7425 15.0281 10.0592C14.3448 9.37585 13.5198 9.03418 12.5531 9.03418C11.5698 9.03418 10.7406 9.37585 10.0656 10.0592C9.39063 10.7425 9.05312 11.5675 9.05312 12.5342C9.05312 13.5008 9.39063 14.3258 10.0656 15.0092C10.7406 15.6925 11.5698 16.0342 12.5531 16.0342Z"
          fill="#353A41"
        />
      </g>
    </svg>
  );
};
