import { useState, useRef, useEffect } from "react";
import { DownArrow } from "src/icons/downArrow";
import { UpArrow } from "src/icons/upArrow";
import { twMerge } from "tailwind-merge";

const renderDefaultOptions = (item: any, onSelect: any) => (
  <div
    className="font-medium text-sm py-2 px-4 cursor-pointer flex gap-2 items-center hover:bg-secondary hover:text-white text-x-gray-100 "
    key={item?.id}
    onClick={() => onSelect(item)}
  >
    <span>{item?.name}</span>
  </div>
);

const Dropdown = ({
  className,
  trigger,
  options = [],
  renderOptions = renderDefaultOptions,
  onSelect = () => {},
}: {
  className?: string;
  trigger: JSX.Element;
  options: any[];
  renderOptions?: (item: any, onClick: any) => JSX.Element;
  onSelect?: (item: any) => void;
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const dropdownRef: any = useRef(null);

  const toggleMenu = () => setShowMenu((prev) => !prev);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={twMerge("relative", className)} ref={dropdownRef}>
      <div className="flex items-center" onClick={toggleMenu}>
        {trigger}
        <span className="ml-4">{showMenu ? <UpArrow /> : <DownArrow />}</span>
      </div>
      {showMenu ? (
        <div className="absolute bg-white min-w-10 border rounded-lg flex flex-col ">
          {options?.map((a) => renderOptions(a, onSelect))}
        </div>
      ) : null}
    </div>
  );
};

export default Dropdown;
