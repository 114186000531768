import { ThinkingIcon } from "src/icons/thinkingIcon";
import { NavItem } from "./types";
import { MathIcon } from "src/icons/mathIcon";
import { WrittingIcon } from "src/icons/writtingIcon";
import { ReadingIcon } from "src/icons/readingIcon";
import { AnalyticsIcon } from "src/icons/analyticsIcon";
import { PracticeIcon } from "src/icons/practiceIcon";
import { MockIcon } from "src/icons/MockIcon";

export const SubjectIcons: any = {
  1: ThinkingIcon,
  2: MathIcon,
  3: ReadingIcon,
  4: WrittingIcon,
};

export const featureIcon: any = {
  1: PracticeIcon,
  2: MockIcon,
};

export const SidebarNav: NavItem[] = [
  {
    icon: AnalyticsIcon,
    label: "Analytics",
    path: "/analytics",
  },
];
