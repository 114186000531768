import { LogoAlt } from "src/assets/images";
import {
  SidebarNav,
  SubjectIcons,
  featureIcon,
} from "src/constants/navigation";
import SideBarMenu from "../sidebar-menu/sidebar-menu";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "src/context/global";
import ParentUser from "../user/parentUser";
import { HomeIcon } from "src/icons/homeIcon";
import { TestRoomLogo } from "src/icons/testRoomLogo";
import { TestFavIcon } from "src/icons/testFavIcon";

interface Props {
  expanded: boolean;
}

const Sidebar = ({ expanded = true }: Props) => {
  const [navItem, setNavItems] = useState([]);

  const styleToApply = [
    "flex flex-col relative h-full border-r relative bg-white",
    expanded ? "w-64" : "w-20",
  ];
  const { state } = useContext(GlobalContext);

  useEffect(() => {
    if (state?.subject) {
      const menuItem = state?.subject?.map((item: any) => {
        const featureId = item?.featureID;
        item.icon = featureIcon[featureId];
        item.label = item?.featureName;
        item.children = item?.children
          ?.map((item: any) => ({
            ...item,
            path: `${featureId === 1 ? "practice" : "mock"}/${featureId}/${
              item.subjectID
            }/${encodeURIComponent(item?.subjectName)}`,
            label: item?.subjectName,
            icon: SubjectIcons[item?.subjectID],
          }))
          .sort(
            (a: any, b: any) => a?.subjectDisplayOrder - b?.subjectDisplayOrder,
          );
        return item;
      });

      const menuMapped = [
        {
          icon: HomeIcon,
          path: "/dashboard",
          label: "Dashboard",
        },
        ...menuItem,
        ...SidebarNav,
      ];

      setNavItems(menuMapped as any);
    }
  }, [state?.subject]);

  return (
    <div className={styleToApply.join(" ")}>
      <div className="py-6 mx-auto">
        <div>{!expanded ? <TestFavIcon /> : <TestRoomLogo />}</div>
      </div>
      <div className="flex-1 overflow-auto">
        <SideBarMenu data={navItem} expanded={expanded} />
      </div>
      <ParentUser expanded={expanded} />
    </div>
  );
};

export default Sidebar;
