export const MathIcon = ({
  color = "#593F4C",
  width = 36,
  height = 36,
}: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
    >
      <g clipPath="url(#clip0_364_1267)">
        <path
          d="M0 3.99985V17H17V0H3.99985C1.79077 0 0 1.79077 0 3.99985ZM5.50003 7.50009H7.50009V5.50003C7.50009 4.94769 7.94778 4.5 8.50012 4.5C9.05246 4.5 9.50015 4.94769 9.50015 5.50003V7.50009H11.5002C12.0523 7.50009 12.5002 7.94778 12.5002 8.50012C12.5002 9.05246 12.0526 9.50015 11.5002 9.50015H9.50015V11.5002C9.50015 12.0523 9.05246 12.5002 8.50012 12.5002C7.94778 12.5002 7.50009 12.0526 7.50009 11.5002V9.50015H5.50003C4.94769 9.50015 4.5 9.05246 4.5 8.50012C4.5 7.94778 4.94769 7.50009 5.50003 7.50009Z"
          fill={color}
        />
        <path
          d="M32.0001 0H19V17H36V3.99985C36 1.79077 34.2092 0 32.0001 0ZM30.4999 9.50015H24.5C23.9477 9.50015 23.5 9.05246 23.5 8.49985C23.5 7.94751 23.9477 7.49982 24.5 7.49982H30.4999C31.0523 7.49982 31.5 7.94751 31.5 8.49985C31.5 9.05246 31.0523 9.50015 30.4999 9.50015Z"
          fill={color}
        />
        <path
          d="M0 32.0001C0 34.2092 1.79077 36 3.99985 36H17V19H0V32.0001ZM5.67197 26.0859C5.28827 25.6885 5.29926 25.0557 5.69641 24.672C6.08395 24.2976 6.69836 24.2976 7.08591 24.672L8.50012 26.0859L9.91406 24.672C10.3115 24.2883 10.9446 24.2993 11.3283 24.6964C11.7024 25.084 11.7024 25.6984 11.3283 26.0859L9.91406 27.5001L11.3283 28.9141C11.7254 29.2978 11.7364 29.9308 11.3527 30.328C10.969 30.7254 10.3359 30.7364 9.93878 30.3527C9.93027 30.3448 9.9223 30.3365 9.91406 30.328L8.50012 28.9141L7.08591 30.328C6.68875 30.712 6.05566 30.701 5.67197 30.3036C5.29761 29.916 5.29761 29.3016 5.67197 28.9141L7.08591 27.5001L5.67197 26.0859Z"
          fill={color}
        />
        <path
          d="M19 36H32.0001C34.2092 36 36 34.2092 36 32.0001V19H19V36ZM24.5 24.5H30.4999C31.0523 24.5 31.5 24.9477 31.5 25.5001C31.5 26.0524 31.0523 26.5001 30.4999 26.5001H24.5C23.9477 26.5001 23.5 26.0524 23.5 25.5001C23.5 24.9477 23.9477 24.5 24.5 24.5ZM24.5 28.4999H30.4999C31.0523 28.4999 31.5 28.9476 31.5 29.4999C31.5 30.0522 31.0523 30.4999 30.4999 30.4999H24.5C23.9477 30.4999 23.5 30.0522 23.5 29.4999C23.5 28.9476 23.9477 28.4999 24.5 28.4999Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_364_1267">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
