export const AnalyticsIcon = ({ color = "#898989" }: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M5.0625 10.125H1.6875C1.37684 10.125 1.125 10.3768 1.125 10.6875V16.3125C1.125 16.6232 1.37684 16.875 1.6875 16.875H5.0625C5.37316 16.875 5.625 16.6232 5.625 16.3125V10.6875C5.625 10.3768 5.37316 10.125 5.0625 10.125Z"
        fill={color}
      />
      <path
        d="M16.3125 5.625H12.9375C12.6268 5.625 12.375 5.87684 12.375 6.1875V16.3125C12.375 16.6232 12.6268 16.875 12.9375 16.875H16.3125C16.6232 16.875 16.875 16.6232 16.875 16.3125V6.1875C16.875 5.87684 16.6232 5.625 16.3125 5.625Z"
        fill={color}
      />
      <path
        d="M10.6875 1.125H7.3125C7.00184 1.125 6.75 1.37684 6.75 1.6875V16.3125C6.75 16.6232 7.00184 16.875 7.3125 16.875H10.6875C10.9982 16.875 11.25 16.6232 11.25 16.3125V1.6875C11.25 1.37684 10.9982 1.125 10.6875 1.125Z"
        fill={color}
      />
    </svg>
  );
};
