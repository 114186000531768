export const PracticeIcon = ({ color }: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M3.65645 6.18707H4.78145C4.93613 6.18707 5.0627 6.06051 5.0627 5.90582V5.25051C5.0627 4.96926 4.9052 4.57832 4.5002 4.45176V3.95113L5.8052 4.38707L5.36082 5.72301C5.33551 5.80176 5.34395 5.88613 5.38613 5.95645C5.42832 6.02676 5.49863 6.07457 5.5802 6.08863L8.9552 6.65113C8.96926 6.65395 8.98613 6.65395 9.0002 6.65395C9.01426 6.65395 9.03113 6.65395 9.0452 6.65113L12.4202 6.08863C12.5018 6.07457 12.5721 6.02676 12.6143 5.95645C12.6564 5.88613 12.6649 5.80176 12.6396 5.72301L12.1952 4.38707L14.4311 3.64176C14.5436 3.6052 14.6196 3.50395 14.6224 3.38582C14.628 3.2677 14.5577 3.16082 14.4508 3.11582L9.10707 0.86582C9.03676 0.837695 8.95801 0.837695 8.8877 0.86582L3.54395 3.11582C3.43707 3.16082 3.36676 3.2677 3.37238 3.38582C3.37801 3.50395 3.45395 3.6052 3.56363 3.64176L3.9377 3.76551V4.45176C3.5327 4.57832 3.3752 4.96926 3.3752 5.25051V5.90582C3.3752 6.06051 3.50176 6.18707 3.65645 6.18707ZM6.31707 3.67551C6.71363 3.42238 7.58832 3.00051 9.0002 3.00051C10.4121 3.00051 11.2868 3.4252 11.6833 3.67551C11.8155 3.75988 11.8521 3.93426 11.7705 4.06363C11.7171 4.14801 11.6271 4.19301 11.5343 4.19301C11.4836 4.19301 11.4302 4.17895 11.3824 4.14801C11.0393 3.92863 10.2714 3.5602 9.00301 3.5602C7.73457 3.5602 6.96676 3.92863 6.62363 4.14801C6.49426 4.23238 6.31989 4.19301 6.23551 4.06082C6.14832 3.93426 6.1877 3.75988 6.31707 3.67551ZM3.9377 5.25332C3.94051 5.12395 3.99395 4.96926 4.21895 4.96926C4.44676 4.96926 4.49738 5.12395 4.5002 5.25051V5.62457H3.9377V5.25332Z"
        fill="#898989"
      />
      <path
        d="M14.8866 8.14185V7.76497C14.8866 7.56528 14.8078 7.37685 14.6644 7.23903C14.5209 7.09841 14.3269 7.02247 14.13 7.03091C11.3428 7.10966 9.48656 7.85497 9 8.07435C8.51344 7.85497 6.66 7.11247 3.87 7.03091C3.6675 7.02247 3.47906 7.09841 3.33562 7.23903C3.19219 7.37966 3.11344 7.56528 3.11344 7.76497V8.14185C2.61844 8.26841 2.25 8.71278 2.25 9.24716V15.2856C2.25 15.9156 2.76187 16.4303 3.39469 16.4303H14.6081C15.2381 16.4303 15.7528 15.9184 15.7528 15.2856V9.24716C15.75 8.71278 15.3816 8.26841 14.8866 8.14185ZM14.1441 7.59341C14.1919 7.59341 14.2369 7.61028 14.2706 7.64403C14.3044 7.67778 14.3241 7.71997 14.3241 7.76778V14.6247C14.3241 14.7175 14.2509 14.7934 14.1581 14.7962C11.7422 14.8609 10.0238 15.4262 9.27844 15.7215V8.56653C9.79875 8.34435 11.5734 7.66653 14.1441 7.59341ZM3.67594 7.76497C3.67594 7.71716 3.69563 7.67497 3.72938 7.64122C3.76313 7.61028 3.80531 7.5906 3.85312 7.5906H3.85875C6.42938 7.66372 8.20406 8.34153 8.72156 8.56372V15.7159C7.97906 15.4206 6.25781 14.8553 3.84187 14.7906C3.75187 14.7878 3.67594 14.7118 3.67594 14.619V7.76497Z"
        fill="#898989"
      />
      <path
        d="M4.45243 8.88727C4.73368 8.90696 5.01774 8.93227 5.29899 8.96602C5.31024 8.96602 5.32149 8.96883 5.33274 8.96883C5.47336 8.96883 5.5943 8.86477 5.61118 8.72133C5.63086 8.56665 5.52118 8.42602 5.36649 8.40915C5.07399 8.37258 4.77868 8.34727 4.48899 8.32758C4.3343 8.31915 4.1993 8.43446 4.19086 8.58915C4.18243 8.74383 4.29774 8.87602 4.45243 8.88727Z"
        fill="#898989"
      />
      <path
        d="M4.19019 9.47769C4.17894 9.63238 4.29707 9.76738 4.45176 9.77582C4.98051 9.80957 5.50644 9.87144 6.01551 9.95863C6.03238 9.96144 6.04644 9.96144 6.06332 9.96144C6.19832 9.96144 6.31645 9.86582 6.33895 9.728C6.36426 9.57613 6.26301 9.42988 6.10832 9.40457C5.58238 9.31457 5.03676 9.24988 4.48832 9.21332C4.33363 9.20488 4.20144 9.323 4.19019 9.47769Z"
        fill="#898989"
      />
      <path
        d="M4.19019 10.9876C4.17894 11.1423 4.29707 11.2773 4.45176 11.2857C5.94238 11.3841 7.1152 11.6766 7.8352 11.9045C7.86332 11.9129 7.89144 11.9185 7.91957 11.9185C8.03769 11.9185 8.1502 11.8426 8.18676 11.7216C8.23457 11.5726 8.15019 11.4151 8.00394 11.3701C7.25582 11.1338 6.03519 10.8301 4.48832 10.726C4.33363 10.7176 4.20144 10.8329 4.19019 10.9876Z"
        fill="#898989"
      />
      <path
        d="M8.00394 12.4199C7.25582 12.1836 6.03801 11.8799 4.48832 11.7758C4.33363 11.7674 4.19863 11.8827 4.19019 12.0374C4.17894 12.1921 4.29707 12.3271 4.45176 12.3355C5.94238 12.434 7.1152 12.7265 7.8352 12.9543C7.86332 12.9627 7.89144 12.9683 7.91957 12.9683C8.03769 12.9683 8.1502 12.8924 8.18676 12.7715C8.23457 12.6252 8.15301 12.4649 8.00394 12.4199Z"
        fill="#898989"
      />
      <path
        d="M8.00394 13.5136C7.25582 13.2774 6.03519 12.9736 4.48832 12.8696C4.33082 12.8611 4.19863 12.9765 4.19019 13.1311C4.17894 13.2858 4.29707 13.4208 4.45176 13.4293C5.94238 13.5277 7.1152 13.8202 7.8352 14.048C7.86332 14.0565 7.89144 14.0621 7.91957 14.0621C8.03769 14.0621 8.1502 13.9861 8.18676 13.8652C8.23457 13.719 8.15301 13.5586 8.00394 13.5136Z"
        fill="#898989"
      />
      <path
        d="M10.0768 11.9185C10.1049 11.9185 10.133 11.9157 10.1611 11.9045C10.8811 11.6766 12.0511 11.3841 13.5446 11.2857C13.6993 11.2745 13.8174 11.1423 13.8061 10.9876C13.7949 10.8329 13.6627 10.7176 13.508 10.726C11.9611 10.8273 10.7433 11.1338 9.99238 11.3701C9.84332 11.4179 9.76176 11.5754 9.80957 11.7216C9.84894 11.8426 9.95863 11.9185 10.0768 11.9185Z"
        fill="#898989"
      />
      <path
        d="M10.0768 12.9683C10.1049 12.9683 10.133 12.9655 10.1611 12.9543C10.8811 12.7265 12.0511 12.434 13.5446 12.3355C13.6993 12.3243 13.8174 12.1921 13.8061 12.0374C13.7949 11.8827 13.6599 11.7674 13.508 11.7758C11.9611 11.8799 10.7433 12.1836 9.99238 12.4199C9.84332 12.4677 9.76176 12.6252 9.80957 12.7715C9.84894 12.8924 9.95863 12.9683 10.0768 12.9683Z"
        fill="#898989"
      />
      <path
        d="M10.0768 14.0624C10.1049 14.0624 10.133 14.0596 10.1611 14.0483C10.8811 13.8205 12.0511 13.528 13.5446 13.4296C13.6993 13.4183 13.8174 13.2861 13.8061 13.1314C13.7949 12.9768 13.6627 12.8586 13.508 12.8699C11.9611 12.9711 10.7433 13.2777 9.99238 13.5139C9.84332 13.5618 9.76176 13.7193 9.80957 13.8655C9.84894 13.9864 9.95863 14.0624 10.0768 14.0624Z"
        fill="#898989"
      />
    </svg>
  );
};
