export const WrittingIcon = ({
  color = "#593F4C",
  width = 36,
  height = 36,
}: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
    >
      <g clipPath="url(#clip0_364_1285)">
        <path
          d="M34.9453 13.356H32.6953C32.1128 13.356 31.6406 13.8282 31.6406 14.4106V29.8794C31.6406 30.4619 32.1128 30.9341 32.6953 30.9341H34.9453C35.5278 30.9341 36 30.4619 36 29.8794V14.4106C36 13.8282 35.5278 13.356 34.9453 13.356Z"
          fill={color}
        />
        <path
          d="M20.3935 6.69965L22.1513 3.65505C22.5396 2.98244 22.3092 2.12238 21.6366 1.73404L20.4187 1.03092C19.7461 0.642584 18.8861 0.873068 18.4978 1.54568L16.74 4.59028L20.3935 6.69965Z"
          fill={color}
        />
        <path
          d="M1.25277 24.7012C1.85542 25.0447 2.62443 24.8203 2.97128 24.2196L11.5903 9.29113C10.7862 9.10009 9.92814 9.27004 9.25293 9.77643L6.26697 12.0159C5.39805 12.6676 4.67608 13.495 4.14817 14.4443L0.313119 21.3402C-0.345217 22.524 0.0677279 24.017 1.24075 24.6943C1.24476 24.6966 1.24877 24.6988 1.25277 24.7012Z"
          fill={color}
        />
        <path
          d="M7.50654 21.1004H12.0791L19.3387 8.52637L15.6851 6.41699L7.20117 21.1118C7.30221 21.1051 7.40381 21.1004 7.50654 21.1004Z"
          fill={color}
        />
        <path
          d="M3.40566 27.6855L2.31448 29.5755C2.26315 29.6644 2.22511 29.7603 2.20148 29.8602L1.19524 34.1164C1.00266 34.931 1.98584 35.4986 2.59502 34.9246L5.77793 31.9251C5.8526 31.8547 5.91666 31.7738 5.96798 31.6849L6.83072 30.1906C5.32455 29.9664 4.0564 29.0046 3.40566 27.6855Z"
          fill={color}
        />
        <path
          d="M15.8998 30.2412H9.23751L7.84546 32.6522C8.29307 33.1161 8.92075 33.4052 9.61628 33.4052C10.9754 33.4052 12.0772 32.3034 12.0772 30.9443C12.0772 32.3034 13.179 33.4052 14.5382 33.4052C15.8973 33.4052 16.9991 32.3034 16.9991 30.9443V30.7836C16.7438 30.6668 16.1591 30.3698 15.8998 30.2412Z"
          fill={color}
        />
        <path
          d="M20.0964 11.4326L14.5148 21.1002H16.1719C16.7544 21.1002 17.2266 21.5725 17.2266 22.1549C17.2266 22.7373 16.7545 23.2095 16.172 23.2096H7.50659C6.14745 23.2096 5.04565 24.3114 5.04565 25.6705C5.04565 27.0297 6.14745 28.1315 7.50659 28.1315H16.3975L17.564 28.7142C19.5601 29.7124 21.7961 30.2408 24.0302 30.2408C25.881 30.2408 27.7943 29.8771 29.5313 29.185V15.475H27.8691L20.0964 11.4326Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_364_1285">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
