export const ThinkingIcon = ({
  color = "#593F4C",
  width = 36,
  height = 36,
}: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
    >
      <g clipPath="url(#clip0_364_1255)">
        <path
          d="M18 5.1855C18.621 5.1855 19.125 4.6815 19.125 4.0605V1.125C19.125 0.504 18.621 0 18 0C17.379 0 16.875 0.504 16.875 1.125V4.0605C16.875 4.6815 17.379 5.1855 18 5.1855Z"
          fill={color}
        />
        <path
          d="M8.14344 9.26853C8.43144 9.26853 8.71944 9.15903 8.93844 8.93853C9.37794 8.49903 9.37794 7.78653 8.93844 7.34703L6.86244 5.27103C6.42294 4.83153 5.71044 4.83153 5.27094 5.27103C4.83144 5.71053 4.83144 6.42303 5.27094 6.86253L7.34694 8.93853C7.56744 9.15753 7.85544 9.26853 8.14344 9.26853Z"
          fill={color}
        />
        <path
          d="M1.12505 19.125H4.06055C4.68155 19.125 5.18555 18.621 5.18555 18C5.18555 17.379 4.68155 16.875 4.06055 16.875H1.12505C0.504045 16.875 4.52995e-05 17.379 4.52995e-05 18C4.52995e-05 18.621 0.504045 19.125 1.12505 19.125Z"
          fill={color}
        />
        <path
          d="M6.06744 31.0576C6.35544 31.0576 6.64344 30.9481 6.86244 30.7276L8.93844 28.6516C9.37794 28.2121 9.37794 27.4996 8.93844 27.0601C8.49894 26.6206 7.78644 26.6206 7.34694 27.0601L5.27094 29.1361C4.83144 29.5756 4.83144 30.2881 5.27094 30.7276C5.49144 30.9481 5.77944 31.0576 6.06744 31.0576Z"
          fill={color}
        />
        <path
          d="M29.9325 31.0576C30.2205 31.0576 30.5085 30.9481 30.7275 30.7276C31.167 30.2881 31.167 29.5756 30.7275 29.1361L28.6515 27.0601C28.212 26.6206 27.4995 26.6206 27.06 27.0601C26.6205 27.4996 26.6205 28.2121 27.06 28.6516L29.136 30.7276C29.3565 30.9481 29.6445 31.0576 29.9325 31.0576Z"
          fill={color}
        />
        <path
          d="M31.9395 19.125H34.875C35.496 19.125 36 18.621 36 18C36 17.379 35.496 16.875 34.875 16.875H31.9395C31.3185 16.875 30.8145 17.379 30.8145 18C30.8145 18.621 31.3185 19.125 31.9395 19.125Z"
          fill={color}
        />
        <path
          d="M27.8565 9.26853C28.1445 9.26853 28.4325 9.15903 28.6515 8.93853L30.7275 6.86253C31.167 6.42303 31.167 5.71053 30.7275 5.27103C30.288 4.83153 29.5755 4.83153 29.136 5.27103L27.06 7.34703C26.6205 7.78653 26.6205 8.49903 27.06 8.93853C27.282 9.15753 27.5685 9.26853 27.8565 9.26853Z"
          fill={color}
        />
        <path
          d="M13.5 31.5V33.375C13.5 34.815 14.685 36 16.125 36H19.875C21.135 36 22.5 35.04 22.5 32.94V31.5H13.5Z"
          fill={color}
        />
        <path
          d="M11.385 9.8399C13.845 7.8449 17.085 7.0649 20.25 7.7399C24.225 8.5649 27.45 11.8049 28.275 15.7799C29.115 19.8599 27.585 23.9099 24.315 26.3849C23.43 27.0449 22.815 28.0649 22.605 29.2499V29.2649C22.575 29.2499 22.53 29.2499 22.5 29.2499H13.5C13.47 29.2499 13.455 29.2499 13.425 29.2649V29.2499C13.215 28.1099 12.54 27.0599 11.505 26.2499C8.97002 24.2399 7.50002 21.2399 7.50002 17.9999C7.50002 14.8199 8.91002 11.8499 11.385 9.8399ZM12.375 18.7499C12.99 18.7499 13.5 18.2399 13.5 17.6249C13.5 15.3449 15.345 13.4999 17.625 13.4999C18.24 13.4999 18.75 12.9899 18.75 12.3749C18.75 11.7599 18.24 11.2499 17.625 11.2499C14.115 11.2499 11.25 14.1149 11.25 17.6249C11.25 18.2399 11.76 18.7499 12.375 18.7499Z"
          fill={color}
        />
        <path
          d="M22.605 29.25H22.5C22.53 29.25 22.575 29.25 22.605 29.265V29.25Z"
          fill={color}
        />
        <path
          d="M13.425 29.25V29.265C13.455 29.25 13.47 29.25 13.5 29.25H13.425Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_364_1255">
          <rect
            width="36"
            height="36"
            fill="white"
            transform="matrix(-1 0 0 1 36 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
