export const TestFavIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      viewBox="0 0 48 48"
      width={40}
    >
      <defs>
        <style>
          {
            ".cls-2,.cls-4,.cls-7{stroke-width:0}.cls-2{fill:#81bc41}.cls-4{fill:#f15729}.cls-7{fill:#3c4248}"
          }
        </style>
        <clipPath id="clippath">
          <path fill="none" strokeWidth={0} d="M0 0H48V48H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#clippath)">
        <path
          className="cls-7"
          d="M7.81 40.02h2.23v3.09H7.81c-.56 0-1.02-.46-1.02-1.02v-1.05c0-.56.46-1.02 1.02-1.02z"
        />
        <path
          d="M10.01 40.02v3.09h29v-3.14l-29 .06zm26.03 2.58H11.79s-.05-.02-.05-.05.02-.05.05-.05h24.25s.05.02.05.05-.02.05-.05.05zm-23.5-.98c0-.06.08-.1.18-.1h22.43c.1 0 .18.05.18.1s-.08.1-.18.1H12.72c-.1 0-.18-.05-.18-.1zm23.44-.82H11.9c-.1 0-.18-.05-.18-.1s.08-.1.18-.1h24.08c.1 0 .18.05.18.1s-.08.1-.18.1z"
          strokeWidth={0}
          fill="#fcb715"
        />
        <path
          d="M40.38 41.2c-.06.12-.15.38 0 .7.02.04 0 .09-.02.12-.22.19-.96.81-1.29 1.07-.06.05-.15 0-.15-.08 0-.3-.08-.87-.46-1.38-.03-.04-.03-.09 0-.12.12-.17.45-.7.46-1.46 0-.08.09-.13.15-.08l1.29 1.1s.05.08.02.13z"
          fill="#f1d338"
          strokeWidth={0}
        />
        <path
          className="cls-7"
          d="M40.03 41.5L39.86 40.67 40.6 41.12 41.21 41.49 40.6 41.85 39.94 42.36 40.03 41.5z"
        />
        <path
          d="M25.36 36.21c-1.87-5.93-7.74-9.86-7.74-9.86V4.88c8.59 3.98 7.73 11.01 7.73 11.01v20.32"
          fill="#35a1da"
          strokeWidth={0}
        />
        <path
          className="cls-2"
          d="M16.31 28.7c3.36 1.64 7.92 8.35 7.92 8.35-3.34-4.79-14.32-7.49-14.32-7.49V8.1c2.26.39 6.17 1.8 6.17 1.8s.04 19.01.23 18.81z"
        />
        <path
          className="cls-4"
          d="M23.26 37.68s-11.4-5.31-17.95-3.51v-21.7h2.81V31.2s8.82.55 15.14 6.48z"
        />
        <path
          className="cls-2"
          d="M25.29 15.51S25.6 7.7 38.56 4.97v21.47s-12.25 8.97-13.2 9.77c0-.24-.02-20.15-.07-20.7z"
        />
        <path
          className="cls-4"
          d="M40.82 12h1.87v20.53s-9.21-2.42-15.38 4.84c0 0 2.73-6.63 13.19-8.9L40.81 12z"
        />
      </g>
    </svg>
  );
};
